<template>
    <main>
        
        <section class="section pt-3 demo">
            <div class="container">
                
                <div class="credential_link_recovery text-center">
                
                    <h2><strong>Nueva contraseña</strong></h2>

                    <p class="form__success" v-if="flag">Hemos enviados a su cuenta de correo su nueva contraseña</p>
                                        
                </div>    
                <!-- hahshajjasjkkjasj -->
            </div>
        </section>
        
    </main>
</template>

<script>

import { ref } from "vue";
import API from "@/api";
import { useRoute } from 'vue-router';
import store from '@/store';
import Swal from 'sweetalert2'
import { mapActions, mapState } from "vuex";


export default {
    name: 'LinkSendEmailPassword',
    components: {
      
    },
    setup(){
        const flag = ref(false)        
        

        const sendEmail = (hashString) => {            
            API.get("/api/link-send-email-password/" + hashString)

                .then(response => {
                    console.log("password created!")
                    flag.value = true                    
                })
                .catch(error => {
                    console.log(error)                    
                })
        }
        
        return { flag, sendEmail}
    },
    mounted() {
        const route = useRoute()
        let array = route.path.split("/");    
        //debugger    
        this.sendEmail(array[array.length - 1])
    }

}
</script>
